<template>
  <s-crud
    no-border
    title="Sector"
    :config="this.config"
    @save="saveDed($event)"
    add
    @clearForm="clearForm()"
    edit
    restore
    @rowSelected="rowSelected($event)"
    remove
    :filter="filter"
  >
    <template scope="props">
      <v-container
        style="padding-top: 0px"
        v-if="props.item != null"
        class="pb-0"
      >
        <v-row>
          <v-col cols="10" class="s-col-form" sm="8" md="8" lg="8">
            <s-text
              autofocus
              ref="StrDescription"
              v-model="props.item.StrDescription"
              label="Descripción"
            />
          </v-col>
          <v-col cols="12" class="s-col-form" sm="4" md="4" lg="4">
            <s-select-definition
              v-model="props.item.StrStatus"
              :def="1244"
              label="Estado"
            ></s-select-definition>
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template v-slot:StrStatus="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.StrStatus == 1 ? 'success' : 'error'"
      >
        {{ row.StrStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>
  </s-crud>
</template>

<script>
import _sDefinitionDetail from "@/services/Technicalassistance/MaintainerSector/SectorService.js";

export default {
  name: "MaintainerSector",
  props: {
    StrDescription: "",
  },
  data: () => ({
    dedValue: 0,
    filter: {},
    config: {
      service: _sDefinitionDetail,
      model: {
        StrID: "ID",
        StrDescription: "string",
        StrStatus: "status",
        SecStatus: "status",
      },
      headers: [
        { text: "ID", value: "StrID", width: "5%", align: "end", sorteable: true },
        { text: "Definicion", value: "StrDescription", width: "55%", sorteable: true },
        { text: "Estado", value: "StrStatus", width: "5%", align: "center", sorteable: false },
      ],
    },
  }),

  watch: {},

  methods: {
    rowSelected(items) {
      if (items.length > 0) this.SectorID = items[0].StrID;
      console.log(items);
      this.$emit("rowSelected", items);
    },

    refresh(item) {
      this.$emit("refresh", item);
    },
    clearForm() {
      this.$refs.StrDescription.focus();
    },
    saveDed(item) {
      item.SecStatus = 1;
      if (item.StrDescription.length == 0) {
        this.$fun.alert("Registre descripción de valor", "warning");
        return;
      }
      item.save();
    },
  },
};
</script>
