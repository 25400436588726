<template>
  <div>
    <v-row>
      <v-col lg="6">
        <maintainer-sector :isAdmin="true" @rowSelected="rowSelected($event)" :height="100">
        </maintainer-sector>
      </v-col>
      <v-col lg="6">
        <maintainer-sub-sector :isAdmin="true" :StrID="StrID" :height="200">
        </maintainer-sub-sector>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import MaintainerSector from "./MaintainerSector.vue";
import MaintainerSubSector from "./MaintainerSubSector.vue";
export default {
  components: {
    MaintainerSector,
    MaintainerSubSector,
  },
  data() {
    return {
      parentID: 0,
      StrID: 0,
    };
  },
  methods: {
    rowSelected(rows) {
      this.StrID = rows[0].StrID;
    },
  },
};
</script>
