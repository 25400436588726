<template>
  <s-crud
    title="Sub-Sector"
    :config="configSubsector"
    @save="save($event)"
    edit
    add
    remove
    :filter="filter"
    ref="crud"
  >
    <template scope="props">
      <v-container
        style="padding-top: 0px"
        v-if="props.item != null"
        class="pb-0"
      >
        <v-row justify="center">
          <v-col cols="12" class="s-col-form" sm="8" md="8" lg="8">
            <s-text v-model="props.item.SstrDescription" label="Descripción">
            </s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="4" md="4" lg="4">
            <s-select-definition
              v-model="props.item.SstrStatus"
              :def="1245"
              label="Estado"
            ></s-select-definition>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:SstrStatus="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.SstrStatus == 1 ? 'success' : 'error'"
      >
        {{ row.SstrStatus == 1 ? "Activo" : "Inactivo" }}
      </v-chip>
    </template>
  </s-crud>
</template>

<script>
import _sSsectorDetail from "@/services/Technicalassistance/MaintainerSector/SubSectorService.js";

export default {
  name: "MaintainerSubSector",
  components: {},
  props: {
    StrID: { type: Number, default: 0 },
  },
  data() {
    return {
      dialogEdit: false,
      filter: {},
    };
  },
  computed: {
    configSubsector() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          StrID: "int",
          SstrID: "ID",
          SstrDescription: "string",
          SstrStatus: "status",
        },
        service: _sSsectorDetail,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "SstrID", sortable: false },
              {
                text: "Descripción",
                value: "SstrDescription",
                sortable: false,
              },
              { text: "Estado", value: "SstrStatus", sortable: false },
            ]
          : [
              // CABECERA EN CASO NO SE LOGEE COMO ADMIN
              { text: "ID", value: "SstrID", sortable: false },
              {
                text: "Descripción",
                value: "SstrDescription",
                sortable: false,
              },
              { text: "Estado", value: "SstrStatus", sortable: false },
            ],
      };
    },
    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
    },
  },
  methods: {
    Initialize() {
      this.filter = { StrID: this.StrID };
    },
    edit() {
      this.dialogEdit = true;
    },
    rowSelected(rows) {
      if (rows.length > 0) {
        this.$emit("rowSelected", rows);
      }
    },
    save(item) {
      item.StrID = this.StrID;
      if (this.$fun.getSecurity().IsLevelAdmin) {
        //VALIDACIONES
        if (item.SstrDescription.length == 0) {
          this.$fun.alert("Registre la descripción de la SubZona", "warning");
          return;
        }
        item.UsrCreateID = 0;
        item.save();
      } else {
        if (item.SstrDescription.length == 0) {
          this.$fun.alert("Registre la descripción de la subzona", "warning");
          return;
        }
        item.UsrCreateID = 0;
        item.save();
      }
    },
  },
  watch: {
    StrID() {
      this.Initialize();
    },
  },
  created() {
    this.filter = { StrID: this.StrID };
  },
};
</script>
